/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: auto; /* Allow content to grow */
  min-height: 100vh; /* Ensure the page spans the full viewport height */
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: black; /* Fallback background color */
}

/* Background Styling */
.background {
  position: relative;
  width: 100%;
  min-height: 100vh; /* Prevent cutoff by ensuring minimum height */
  display: flex;
  flex-direction: column; /* Stack content vertically */
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* Allow content to wrap if necessary */
  text-align: center;
  overflow: hidden;
  background-image: url('./assets/backgroundwall.png'); /* Replace with your image path */
  background-size: cover; /* Ensure the background covers the viewport */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent tiling */
}

/* Content Styling */
.content {
  padding: 20px;
  color: #fff;
  max-width: 800px; /* Constrain content width */
  text-align: center;
  padding-bottom: 50px; /* Prevent bottom content from being cut off */
}

.content h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.content p {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

/* Form Styling */
.newsletter-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.newsletter-form input[type="email"] {
  padding: 10px;
  width: 100%; /* Responsive width */
  max-width: 400px; /* Limit maximum size */
  border: none;
  border-radius: 5px;
  margin-bottom: 15px;
  font-size: 1rem;
}

.newsletter-form button {
  padding: 10px 20px;
  background-color: #ff7b00;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%; /* Responsive width */
  max-width: 150px; /* Restrict maximum size */
}

.newsletter-form button:hover {
  background-color: #e67000;
}

/* Responsive Design */
/* Tablet View */
@media (max-width: 768px) {
  .content h1 {
      font-size: 2.5rem;
  }

  .content p {
      font-size: 1.1rem;
  }

  .newsletter-form input[type="email"] {
      max-width: 300px;
  }
}

/* Mobile View */
@media (max-width: 480px) {
  .content h1 {
      font-size: 2rem;
  }

  .content p {
      font-size: 1rem;
  }

  .newsletter-form input[type="email"] {
      max-width: 250px;
  }
}
